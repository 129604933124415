<template>
  <div>
    <div class="search">
      <div class="title">景点列表</div>
      <hr />
      <el-form :inline="true" class="top">
        <!-- <el-form-item label="昵称:">
            <el-input v-model="params.nickName"></el-input>
          </el-form-item>
          <el-form-item label="手机号:">
            <el-input v-model="params.phone"></el-input>
          </el-form-item>
          <el-form-item label="时间:">
            <el-date-picker
              :editable="false"
              :picker-options="pickerOptions0"
              value-format="yyyy-MM-dd HH:mm:ss"
              v-model="date"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </el-form-item> -->
        <el-form-item>
          <!-- <el-button type="primary" @click="getList">查询</el-button>
            <el-button type="primary" @click="reset">重置</el-button> -->
          <el-button
            type="primary"
            @click="$router.push('/scenicAdd?daysId=' + $route.query.daysId)"
            >添加</el-button
          >
          <el-button type="primary" @click="$router.go(-1)">返回</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table
      :data="tableData"
      border
      tooltip-effect="dark"
      :row-key="rowKey"
      style="width: 100%"
      align="center"
    >
      <!-- <el-table-column type="selection" width="55" :reserve-selection="true" align="center"> </el-table-column> -->

      <el-table-column label="排序" align="center" width="110">
        <template slot-scope="{ row }">
          <el-input v-model="row.dept" @change="changeSort(row)"></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="travelName" width="300" label="所属线路" align="center">
      </el-table-column>

      <el-table-column prop="daysName" label="所属行程" align="center"> </el-table-column>
      <el-table-column prop="scenicName" label="景点名称" align="center">
      </el-table-column>

      <el-table-column label="操作" width="300" align="center">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" @click="remove(scope.row)"
            >删除</el-button
          >
          <el-button type="primary" size="mini" @click="edit(scope.row)">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="block">
      <!-- <div>
                <el-button type="primary" @click="allCheckVip">批量修改vip</el-button>
              <el-button type="primary" @click="allOpe">批量操作</el-button>
           </div> -->
      <div>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="params.currentPage"
          :page-sizes="[5, 10, 20]"
          :page-size="params.pageSize"
          layout="total,sizes,prev,pager,next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { scenicList, scenicListSort, scenicListDel } from "../../api/travel.js";
export default {
  data() {
    return {
      dialogVisible: false,
      total: 0,
      params: {
        currentPage: 1,
        pageSize: 5,
        daysId: 0,
      },
      addList: {
        cateId: 0,
        cateName: "",
        cateImg: "",
      },
      addRules: {
        tableData: [
          { required: true, message: "请输入活动名称", trigger: ["blur", "change"] },
        ],
      },
      tableData: [],
      date: null,
      pickerOptions0: {
        disabledDate(time) {
          return time.getTime() > Date.now() - 8.64e6;
        },
      },
    };
  },
  created() {
    this.params.daysId = this.$route.query.daysId;
    this.getList();
  },
  methods: {
    rowKey(row) {
      return row.userId;
    },
    changeSort(row) {
      scenicListSort({ dept: row.dept, scenicId: row.scenicId }).then((res) => {
        if (res.data.code == 0) {
          this.$message.success("修改成功");
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    async getList() {
      // this.params.beginTime = this.date ?  this.date[0] : ''
      // this.params.endTime = this.date ? this.date[1] : ''
      const { data } = await scenicList(this.params);
      if (data.code == 0) {
        this.tableData = data.list;
        this.total = data.pagination.total;
      } else {
      }
    },
    handleSizeChange(e) {
      this.params.pageSize = e;
      this.getList();
    },
    // 页数改变
    handleCurrentChange(e) {
      this.params.currentPage = e;
      this.getList();
    },
    reset() {
      this.params.currentPage = 1;
      this.params.pageSize = 5;

      this.date = null;
      this.getList();
    },
    remove(row) {
      this.$confirm("此操作将删除该行程, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          scenicListDel({ daysId: row.scenicId }).then((res) => {
            if (res.data.code == 0) {
              this.$message.success("删除成功");
              this.getList();
            } else {
              this.$message.error(res.data.msg);
            }
          });
        })
        .catch(() => {});
    },
    edit(row) {
      this.$router.push("/scenicAdd?scenicId=" + row.scenicId);
    },
  },
};
</script>

<style scoped>
.title {
  padding: 10px 0;
}
.top {
  margin-top: 20px;
}
.block {
  margin-top: 15px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
</style>
